<template>

    <footer>

        <div class="page-footer">

            <template>

                <img class="branding" v-bind:src="'/static/images/logoSmall.svg'" alt="IntechOpen" />
                <p>© {{year}} IntechOpen. All rights reserved.</p>

            </template>

        </div>

    </footer>

</template>

<script>
export default {
    computed: {
        year() {
            return new Date().getFullYear();
        },
    },
}
</script>

<style lang="scss" scoped>
</style>
