<template>

    <div>

        <PageHeader />
        <div class="wrap">

            <section>

                <div class="content text-center">
                    <h1 class="title">Maintenance in progress...</h1>

                    <p class="body-text" v-if="maintenanceText">{{ maintenanceText }}</p>
                    <p class="body-text" v-else>We are sorry, we are currently working on service improvement.</p>
                            
                    <br><br>

                    <div class="input-field">

                        <a :href="'https://www.intechopen.com'" class="no-underline button-type-1">Return to Website</a>

                    </div>

                    <br>

                </div>

            </section>

        </div>
        <PageFooter />

    </div>

</template>

<script>
    import {mapGetters} from 'vuex'

    import PageHeader from '@/components/main/PageHeader';
    import PageFooter from '@/components/main/PageFooter';

    export default {

        components: {

            PageHeader,
            PageFooter
            
        },

        computed: mapGetters({

            maintenanceText: 'maintenanceText'
            
        }),


    };

</script>

<style scoped>
</style>
