
export function getCookie(cname) {
    try {
        var cookie = document.cookie || "";
        let ca = cookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }

            if (c.indexOf(cname + '=') === 0) {
                var v = c.substring(cname.length + 1);
                v = decodeURIComponent(v);

                return v;
            }
        }
    } catch (err) {
        console.log("getCookie err:", err);
    }
    return '';
}

export function setCookie(cname, value, days, domain) {
    try {
        var buf = cname + "=" + encodeURIComponent(value || "");

        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            buf = buf + "; expires=" + date.toUTCString();
        }

        if (domain) {
            buf = buf + "; domain=" + encodeURIComponent(domain);
        }

        buf = buf + "; path=/";
        buf = buf + "; SameSite=None; Secure";

        document.cookie = buf;
    } catch (err) {
        console.log("setCookie err:", err);
    }
}

export function eraseCookie(cname, domain) {   
    try {
        var buf = cname + "=";

        buf = buf + "; expires=Thu, 01 Jan 1970 00:00:01 GMT";

        if (domain) {
            buf = buf + "; domain=" + encodeURIComponent(domain);
        }

        buf = buf + "; path=/";
        buf = buf + "; SameSite=None; Secure";

        document.cookie = buf;
    } catch (err) {
        console.log("eraseCookie err:", err);
    }
}

export function getTLDdomain() {
    try {
        var hostname = window.location.hostname;

        if (hostname.endsWith(".localhost") || hostname == "localhost") {
            return "localhost";
        }

        if (hostname.endsWith(".intechopen.com") || hostname == "intechopen.com") {
            return ".intechopen.com";
        }

        if (hostname.endsWith(".dev1.intechweb.org") || hostname == "dev1.intechweb.org") {
            return ".dev1.intechweb.org";
        }

        return null;
    } catch (err) {
        console.log("getTLDdomain err:", err);
    }
    return null;
}

export default {
    get: getCookie,
    set: setCookie,
    erase: eraseCookie,
    getTLDdomain: getTLDdomain,
};

