<template>

    <div>

        <PageHeader />
        <NotFound />
        <PageFooter />
    </div>

</template>

<script>
// FIXME: Remove PageFooter
    import PageHeader from '@/components/main/PageHeader';
    import NotFound from '@/components/main/NotFound';
    import PageFooter from '@/components/main/PageFooter';

    export default {

        components: {

            PageHeader,
            NotFound,
            PageFooter
        }

    };

</script>

<style scoped>
</style>
