import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({

    state: {

        params: null,

        errorStatus: null,

        maintenanceText: null,

        maintenanceStatus: true,

    },

    actions: {

        clearData({ commit }) {

            commit('setErrorStatus', null)

        }
    },

    getters: {

        params: state   => state.params,

        errorStatus: state  => state.errorStatus,

        maintenanceText: state => state.maintenanceText,

        maintenanceStatus: state => state.maintenanceStatus,

    },

    mutations: {

        setParams(state, val) {

            state.params = val

        },

        setMaintenanceStatus(state, val) {

            if (val.status === 1) {

                state.maintenanceStatus = false;

            } else {

                state.maintenanceStatus = true;

                state.errorStatus = 503;

                if (val.text) {
                    state.maintenanceText = val.text;
                }
            }
        },

        setErrorStatus(state, val) {

            state.errorStatus = val

        },

    }
})
