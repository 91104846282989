export default [
	{ name: "Prof.", code: "Prof." },
	{ name: "Associate Prof.", code: "Associate Prof." },
	{ name: "Assistant Prof.", code: "Assistant Prof." },
	{ name: "Distinguished Prof.", code: "Distinguished Prof." },
	{ name: "Emeritus Prof.", code: "Emeritus Prof." },
	{ name: "Dr.", code: "Dr." },
	{ name: "Dr.Ing.", code: "Dr.Ing." },
	{ name: "Ph.D.", code: "Ph.D." },
	{ name: "D.Sc.", code: "D.Sc." },
	{ name: "Ph.D. Student", code: "Ph.D. Student" },
	{ name: "M.D.", code: "M.D." },
	{ name: "M.A.", code: "M.A." },
	{ name: "B.A.", code: "B.A." },
	{ name: "M.Sc.", code: "M.Sc." },
	{ name: "B.Sc.", code: "B.Sc." }
];
