<template>
</template>

<script>

import { mapGetters } from 'vuex';

import { getAuthPromise, signOutWrapper } from '@/firebaseConfig';

import Cookie from '@/library/cookie';

export default {
    name: 'SignOutPage',

    components: {
    },

    computed: {
        ...mapGetters({
            params: 'params'
        }),

    },

    mounted() {
        return getAuthPromise()
            .then((auth) => {
                Cookie.erase("sso-token",  Cookie.getTLDdomain());

                return signOutWrapper(auth)
            })
            .then(() => {
                var location = process.env.VUE_APP_FRONTEND_DOMAIN;

                if (this.params && this.params.returl) {
                    location = this.params.returl;
                }

                // if (process.env.NODE_ENV == 'development') {
                //    alert("redirect " + location);
                // }

                window.location = location;
            });

    },

};
</script>

<style lang="scss"></style>
