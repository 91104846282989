import Vue from 'vue'
import Router from 'vue-router'
import { store } from '../store.js'

import AuthPage from '@/pages/AuthPage'
import ActionPage from '@/pages/ActionPage'
import NotFoundPage from '@/pages/NotFoundPage'
import SignOutPage from '@/pages/SignOutPage'

Vue.use(Router)

const router = new Router({

	mode: 'history',
	routes: [

		{
			path: '*',
			name: 'NotFoundPage',
			component: NotFoundPage
		},

		{
			path: '/',
			name: 'AuthPage',
			component: AuthPage
		},

		{
			path: '/action',
			name: 'ActionPage',
			component: ActionPage,
		},

        {
			path: '/logout',
			name: 'SignOutPage',
			component: SignOutPage,
		},

	]

})

router.beforeEach((to, from, next) => {

	let params = to.query;

	//let availableFormTypes = ['login', 'signup', 'passwordreset', 'changeemail'];
	let availableFormTypes = ['login', 'signup', 'passwordreset'];
	let availableReferers = ['sf', 'mng', 'www'];

	//formtype param
	if (availableFormTypes.indexOf(to.query.formtype) > -1) {

		params.formtype = to.query.formtype;

	} else {

		params.formtype = 'login';

	}

	//referer param
	if (availableReferers.indexOf(to.query.referer) > -1) {

		params.referer = to.query.referer;

	} else {

		// params.referer = 'mng';

        // default.
		params.referer = 'sf';

	}

	//returl param
    /*
	if (!params.returl) {

        if (to.name == 'SignOutPage') {
            // no default.

        } else {
            //params.returl = 'https://www.intechopen.com/auth/sign-in';

            params.returl = 'https://mts.intechopen.com/index/loginwithfirebase';
        }
	}
    */

	store.commit('setParams', params);

	next();

});

router.afterEach((to, from) => {
    if (window && window.gtag) {

        var page_view = {
                // 'page_title': to.name, // default: document.title
                'page_location': process.env.VUE_APP_SSO_DOMAIN + to.path,
                // page_path
                // send_to: '<GA_MEASUREMENT_ID>'
                'page_class': to.name,
                'page_subdomain': 'auth',
        };

        if (to.name == 'AuthPage') {
            if (to.query && to.query.callemail) {
                page_view.page_class = 'AuthPage.email';
            }
        }

        window.gtag('event', 'page_view', page_view);
    }
});

export default router
