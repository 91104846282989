<template>
  <div class="wrap">
        <transition name="fade">

            <div v-if="performingRequest" class="loading">
                <p>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="background: none;">
                        <rect x="19" y="19" width="20" height="20" fill="#D9D8D6"><animate attributeName="fill" values="#DE0A17;#D9D8D6;#D9D8D6" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0s" calcMode="discrete"/></rect>
                        <rect x="40" y="19" width="20" height="20" fill="#D9D8D6"><animate attributeName="fill" values="#DE0A17;#D9D8D6;#D9D8D6" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.125s" calcMode="discrete"/></rect>
                        <rect x="61" y="19" width="20" height="20" fill="#D9D8D6"><animate attributeName="fill" values="#DE0A17;#D9D8D6;#D9D8D6" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.25s" calcMode="discrete"/></rect>
                        <rect x="19" y="40" width="20" height="20" fill="#D9D8D6"><animate attributeName="fill" values="#DE0A17;#D9D8D6;#D9D8D6" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.875s" calcMode="discrete"/></rect>
                        <rect x="61" y="40" width="20" height="20" fill="#D9D8D6"><animate attributeName="fill" values="#DE0A17;#D9D8D6;#D9D8D6" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.375s" calcMode="discrete"/></rect>
                        <rect x="19" y="61" width="20" height="20" fill="#D9D8D6"><animate attributeName="fill" values="#DE0A17;#D9D8D6;#D9D8D6" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.75s" calcMode="discrete"/></rect>
                        <rect x="40" y="61" width="20" height="20" fill="#D9D8D6"><animate attributeName="fill" values="#DE0A17;#D9D8D6;#D9D8D6" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.625s" calcMode="discrete"/></rect>
                        <rect x="61" y="61" width="20" height="20" fill="#D9D8D6"><animate attributeName="fill" values="#DE0A17;#D9D8D6;#D9D8D6" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.5s" calcMode="discrete"/></rect>
                    </svg>
                </p>
            </div>

        </transition>

        <section>

            <div class="content">
                <h1 v-if="this.getParams.formtype == 'login'" class="title">Login to IntechOpen</h1>
                <h1 v-if="this.getParams.formtype == 'signup'" class="title">Create an account</h1>
                <h1 v-if="this.getParams.formtype == 'passwordreset'" class="title">Change your password</h1>

                <!-- TODO: Remove once we remove changeemail form -->
                <!--<h1 v-if="this.getParams.formtype == 'changeemail'" class="title">Change your email</h1>-->

                <!-- TODO: Separate messaging into new component -->
                <div v-if="errorMsg !== ''" class="error-msg">

                    <p v-if="errorMsg == 'auth/invalid-email'">Invalid email format. Please try again.</p>
                    <p v-else-if="errorMsg == 'auth/wrong-password'">You have entered an incorrect password. Please try again. Forgotten your password? You can recover or change your password <a v-on:click="switchToPasswordReset">here</a>.</p>
                    <p v-else-if="errorMsg == 'auth/user-not-found'">You have entered an incorrect email. Please try again. If you do not have an account please create one <a v-on:click="switchToSignup">here</a></p>
                    <p v-else-if="errorMsg == 'signup/missing-fields'">Please enter all required fields.</p>
                    <p v-else-if="errorMsg == 'signup/missing-privacy-check'">You must accept the Open Access Publishing fee policy and Privacy Policy in order to proceed.</p>
                    <p v-else-if="errorMsg == 'auth/weak-password'">Please enter another password that is at least six characters, and contains at least one letter and one number, it can include special characters.</p>
          <!-- <p v-else-if="errorMsg == 'auth/email-already-in-use'">We already have an account for this email, please login with your user name and password or reset your password <a v-on:click="switchToPasswordReset">here</a></p> -->
                    <p v-else-if="errorMsg == 'auth/email-already-in-use'">We already have an account for this email, please login below</p>
                    <p v-else-if="errorMsg == 'auth/user-disabled'">It seems like the account you are trying to login with is disabled please make sure you are using the correct account information.</p>
                    <p v-else-if="errorMsg == 'auth/too-many-requests'">You had too many unsuccessful login attempts. For security reasons please try again in a few minutes.</p>
                    <p v-else-if="errorMsg == 'signup/repeat-email-must-match'">The email addresses must match.</p>
                    <p v-else-if="errorMsg == 'Please login to complete this actions.'">Please login to complete this actions.</p>
                    <p v-else>Oops something went wrong! Please try again.</p>

                    <div id="close" class="error-close" v-on:click="errorClose">
                        <!--<span>Hide</span>-->
                        <svg class="close-icon" width="16" height="16" viewBox="0 0 31 31" xmlns="http://www.w3.org/2000/svg">
                            <g fill="#e10000">
                            <path d="M1.88235294 30.5318606L.46813938 29.1176471 29.3347339.25105258l1.4142135 1.41421356z"></path>
                            <path d="M29.33473306 30.5318606l1.41421356-1.4142135L1.8823521.25105258.4681386 1.66526614z"></path>
                            </g>
                        </svg>
                    </div>
                </div>

                <!-- Login form -->
                <form v-if="this.getParams.formtype == 'login'" @submit.prevent class="auth-form">
                    <div class="input-field required">
                        <label for="email1">Email address</label>
                        <input v-model.trim="loginForm.email" type="text" placeholder="name@emailaddress.com" id="email1" class="text-input" />
                    </div>
                    <div class="input-field required">
                        <label for="password1">Password</label>
                        <input v-model.trim="loginForm.password" type="password" placeholder="Enter your password" id="password1" class="text-input" />
                        <a v-on:click="switchToPasswordReset">Forgot your password?</a>
                    </div>
                    <div class="input-field">
                        <button v-on:click="loginFirebase" class="button-type-1 auth-form">Login</button>
                    </div>
                    <div class="input-field">
                        <p>Don’t have an account? Create a free account <a v-on:click="switchToSignup">here</a></p>
                    </div>
                    <div class="input-field">
                        <p>View our <a target="_blank" href="https://www.intechopen.com/privacy-policy">privacy policy</a></p>
                    </div>
                </form>

                <!-- Signup form -->
                <form v-if="this.getParams.formtype == 'signup'" @submit.prevent class="auth-form auth-form-reg">
                    <div class="input-field text-center">
                        <p>Creating an account is simple and easy. Thousands of researchers are part of the IntechOpen community. Having your account allows you to submit your work and keep track on the status of your paper.</p>
                    </div>
                    <div class="input-field text-center">
                       <p>Already have an account? <a v-on:click="switchToLogin">Login here</a></p>
                    </div>
                    <div class="float-left-reg">
                        <div class="input-field required">
                            <label for="firstname">First name</label>
                            <input v-model.trim="signupForm.name" type="text" placeholder="  E.g. John" id="firstname" class="text-input" />
                        </div>
                        <div class="input-field required">
                            <label for="lastname">Last name</label>
                            <input v-model.trim="signupForm.lastname" type="text" placeholder="  E.g. Doe" id="lastname" class="text-input" />
                        </div>
                        <div class="input-field required">
                            <label for="email2">Email</label>
                            <input v-model.trim="signupForm.email" type="text" placeholder="  E.g. name@emailaddress.com" id="email2" class="text-input" />
                        </div>

                        <!--
                        <div class="input-field required">
                            <label for="email3">Repeat email</label>
                            <input v-model.trim="signupForm.email2" type="text" placeholder="Repeat email address" id="email3" class="text-input" />
                        </div>
                        -->

                        <div v-if="this.mandatoryProposalTitleCheck()" class="input-field required">
                            <label for="chapterTitle">Chapter proposal title</label>
                            <div id="hover_label">?</div>

                            <div id="title_hover">
                                The working title of the chapter you intend to contribute to the
                                book project
                            </div>
                            <input
                                v-model.trim="signupForm.chapterTitle"
                                type="text"
                                id="chapterTitle"
                                class="text-input"
                            />
                        </div>

                    </div>
                    <div class="float-right-reg">
                        <div class="input-field required">
                            <label for="password2">Password</label>
                            <input v-model.trim="signupForm.password" type="password" placeholder="  Min 6 characters" id="password2" class="text-input" />
                        </div>

                        <div class="input-field required">
                            <label for="title">Academic title</label>
                            <v-select
                                :options="academicTitles"
                                v-model.trim="signupForm.title"
                                :reduce="title => title.code"
                                label="name"
                                placeholder="E.g. Prof."
                                :clearable="false"
                            >
                                <template slot="option" slot-scope="option">
                                    {{ option.name }}
                                </template>
                                <template #selected-option="{ name }">
                                    <div style="display: flex; align-items: baseline;">
                                        <span>{{ name | trimString }}</span>
                                    </div>
                                </template>
                            </v-select>
                        </div>

                        <!--
                        <div class="input-field required">
                            <label for="institution">Institution</label>
                            <v-select
                                :options="institutionOptions"
                                :filterable="false"
                                v-model.trim="signupForm.institution"
                                :reduce="country => country.grid_id"
                                label="name"
                                placeholder="Institution"
                                @search="onSearchInstitution"
                                type="search"
                                autocomplete="block"
                            >
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                        No results found for <em>{{ search }}</em>.
                                    </template>
                                    <span v-else>
                                        Enter the name of institution...
                                    </span>
                                </template>
                                <template slot="option" slot-scope="option">
                                    {{ option.name }}
                                </template>
                                <template #selected-option="{ name }">
                                    <div style="display: flex; align-items: baseline;">
                                        <span>{{ name | trimString }}</span>
                                    </div>
                                </template>
                            </v-select>
                        </div>

                        <div class="input-field required" v-if="signupForm.institution == gridIdOther">
                            <label for="institution_other">Unlisted institution</label>
                            <input v-model.trim="signupForm.institutionOther" type="text" placeholder="Unlisted institutio name..." id="institution_other" class="text-input" />
                        </div>
                        -->


                        <div class="input-field required">
                            <label for="country">Country</label>
                            <!-- Select before had getParams.referer == 'www' condition -->
                            <!-- referer == 'www' => WebSite Accepts Full Country Name Not Country Code -->
                            <v-select
                                :options="countryOptions"
                                v-model.trim="signupForm.country"
                                :reduce="country => country.code"
                                label="name"
                                placeholder="E.g. United Kingdom"
                                type="search"
                                autocomplete="block"
                                :clearable="false"
                                :filterable="false" 
                                @search="onSearchCountry"
                            >
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                        No results found for <em>{{ search }}</em>.
                                    </template>
                                    <span v-else>
                                        Enter the name of your country...
                                    </span>
                                </template>
                                <template slot="option" slot-scope="option">
                                    {{ option.name }}
                                </template>
                                <template #selected-option="{ name }">
                                    <div style="display: flex; align-items: baseline;">
                                        <span>{{ name | trimString }}</span>
                                    </div>
                                </template>
                            </v-select>
                        </div>
                        <div v-if="this.getParams.formV !== undefined && this.getParams.formV !== null && this.getParams.formV.search('O') >= 0" class="input-field">
                            <label for="orcid">ORCID ID</label> 
                            <input v-model.trim="signupForm.orcid"
                            type="text"
                            placeholder="XXXX-XXXX-XXXX-XXXX"
                            title="XXXX-XXXX-XXXX-XXXX"
                            pattern="^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{3}[0-9X]{1}$"
                            id="orcid"
                            class="text-input">
                        </div>
                    </div>
                    <div class="clear"></div>

                    <div class="input-field text-center">
                        <p v-if="isFromWelcomePage">As a registered user you agree that IntechOpen can collect and use your personal data as detailed in our <a target="_blank" href="https://www.intechopen.com/privacy-policy">Privacy Statement.</a></p>
                        <p v-else-if="isFromMindsmiths"><input class="reg-input" id="checkbox" type="checkbox" name="checkbox" v-model="checkboxState"><label for="checkbox">I have read and accept the <a class="red-underlined" @click="openModal">Open Access Publishing Fee (click here)</a> policy and  <a class="red-underlined" href="https://www.intechopen.com/privacy-policy" target="_blank">Privacy Policy</a>.</label></p>
                        <p v-else><input class="reg-input" id="checkbox" type="checkbox" name="checkbox" v-model="checkboxState"><label for="checkbox">I have read and accept the <a class="red-underlined" href="https://www.intechopen.com/privacy-policy" target="_blank">Privacy Policy</a>.</label></p>
                        <div class="input-field">
                            <button v-on:click="signupFirebase" class="button-type-1 auth-form sign-up">Sign Up</button>
                        </div>
                        <oapf-modal v-model="showModal" />
                    </div>
                </form>

                <!-- Password reset form -->
                <form v-if="this.getParams.formtype == 'passwordreset'" @submit.prevent class="auth-form">
                    <div v-if="!passwordResetSuccess">
                        <p>We will send you an email to reset your password</p>

                        <div class="input-field required">
                            <label for="email3">Email</label>
                            <input v-model.trim="passwordForm.email" type="text" placeholder="name@emailaddress.com" id="email3" class="text-input" />
                        </div>

                        <div class="input-field">
                            <button v-on:click="resetPassword" class="button-type-1 auth-form">Submit</button>
                        </div>

                        <div class="input-field">
                            <a v-on:click="switchToLogin">Back to Login</a>
                        </div>
                        <p>Note: if you do not remember which email address you have used in the past feel free to contact us at <a href="mailto:info@intechopen.com">info@intechopen.com</a> for support.</p>
                    </div>
                    <div v-else>
                        <h2 class="sub-title">Email Sent</h2>
                        <p>Check your email for a link to reset your password</p>
                        <div class="input-field">
                            <a v-on:click="switchToLogin">Back to Login</a>
                        </div>
                    </div>
                </form>

                <!-- TODO: Remove once we remove changeemail form;
                     Change email form -->
                <!--<form v-if="this.getParams.formtype == 'changeemail'" @submit.prevent class="auth-form">
                    <div>
                        <p>Insert new email</p>

                        <div class="input-field required">
                            <label for="email3">Email</label>
                            <input v-model.trim="changeEmailForm.email" type="text" placeholder="name@emailaddress.com" id="email3" class="text-input" />
                        </div>

                        <div class="input-field">
                            <button v-on:click="changeEmail" class="button-type-1 auth-form">Submit</button>
                        </div>

                        <p>Note: if you experience any problems feel free to contact us at <a href="mailto:info@intechopen.com">info@intechopen.com</a> for support.</p>
                    </div>
                </form>-->

            </div>

        </section>

    </div>
</template>

<script>

    import VueSelect from 'vue-select';
    import debounce from 'lodash/debounce';
    import { mapGetters } from 'vuex';

    import academicTitles from '@/library/academicTitles'
    import countries from '@/library/countries'

    import OapfModal from './main/OapfModal';

    //TODO: Hide this somehow
    import privateKey from '@/library/privateKey'
    // import publicKey from '@/library/publicKey'

    import { getAuthPromise } from "@/firebaseConfig";
    // import { signInWithEmailAndPassword } from "firebase/auth";
    // import { createUserWithEmailAndPassword } from "firebase/auth";
    // import { sendPasswordResetEmail } from "firebase/auth";

    import {
        signInWithEmailAndPassword,
        createUserWithEmailAndPassword,
        sendPasswordResetEmail,
    } from "@/firebaseConfig";

    const axios = require('axios')
    const jwt = require('jsonwebtoken')
    const sha1 = require('sha1')
    const md5 = require('blueimp-md5');

    import Cookie from '@/library/cookie';

    // const gridIdOther = 'grid.0000.2';
    // const gridIdNoInstitution = 'grid.0000.3';

    const sfCreateUserAPI = process.env.VUE_APP_SF_CREATE_USER_API;
    const mtsTokenAuthAPI = process.env.VUE_APP_MTS_AUTH_TOKEN_API;

    const validEmailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    function generateSessionToken(uid, email) {
        let now = Date.now();
        if (window && ("clockDiff" in window)) {
            console.log("clockDiff:", window.clockDiff);
            now += window.clockDiff;
        }

        let iat = Math.floor(now / 1000);
        let exp = Math.floor(now / 1000) + 30 * 24 * 3600;

        let payload = {
            uid: uid,
            email: email,
            exp: exp,
            iat: iat,
        };

        console.log("payload:", payload);

        let secret = privateKey.join('\n')
        let token = jwt.sign(payload, secret, {algorithm: 'RS256'})

        return token;
    }

    export default {

        components: {
            'v-select': VueSelect,
            OapfModal
        },

        mounted () {


            const isMindsmiths = String(this.getParams.ms || 'n').toLowerCase();
            const bookId = this.getParams.bookid || '';
            if (bookId && isMindsmiths === 'n') {
                this.isFromWelcomePage = true;
            } else if(bookId && isMindsmiths === 'y') {
                this.isFromMindsmiths = true;
            }
            this.sourceParam = Cookie.get('sourceParam');

            return getAuthPromise()
                .then((auth) => {
                    return this.afterMounted(auth);
                });
        },

        data() {

            return {

                clockDiff: 0,

                loginForm: {

                    email: '',
                    password: ''

                },

                signupForm: {
                    name: '',
                    lastname: '',
                    email: '',
                    email2: '',
                    password: '',
                    title: '',
                    chapterTitle: '',
                    // institution: '',
                    // institutionOther: '',
                    country: '',
                    orcid: '',
                },

                passwordForm: {

                    email: ''

                },

                passwordResetSuccess: false,
                performingRequest: false,
                errorMsg: '',
                // countryOptions: [],
                countryOptions: countries,
                institutionOptions: [],

                formIsValid: true,
                checkboxState: false,
                showModal: false,
                isFromMindsmiths: false,
                isFromWelcomePage: false,

                sourceParam: '',

                ssoToken: null,

                // gridIdOther: gridIdOther,

                // institutionSearchTerm: '',
                // institutionSearchWIP: false,

            }

        },

        computed: Object.assign(mapGetters({

            params: 'params'

        }), {

            getParams() {
                return this.params;
            },

            academicTitles() {
                return academicTitles;
            },

            countries() {
                return countries;
            },

        }),


        filters: {
            trimString: function(value) {
                return value.length > 18 ? `${ value.slice(0, 15) }...` : value;
            }
        },

        watch: {
            /*
            "signupForm.institution": function (to, from) {
                if (to == null || to == '' || to == 'other') {
                    this.signupForm.country = '';
                    this.countryOptions = [];
                    return;
                }

                var institution = this.institutionOptions.filter((item) => {
                        return item.grid_id == to;
                    });

                if (institution.length > 0) {

                    this.countryOptions = this.countries.filter((item) => {
                            return item.code == institution[0].country_code;
                        });

                    if (this.countryOptions.length > 0) {
                        this.signupForm.country = this.countryOptions[0].code;
                    }
                }
            },
            */
        },

        methods: {

            afterMounted(auth) {

                var promise = Promise.resolve();

                promise = promise.then(() => {
                        this.logData({
                            note: "afterMounted",
                            dataset: {},
                            email: auth && auth.currentUser ? auth.currentUser.email : null,
                            uid: auth && auth.currentUser ? auth.currentUser.uid : null,
                        });
                    });

                if (!auth || !auth.currentUser) {
                    return promise;
                }


                promise = promise.then(() => {
                        
                        this.performingRequest = true;

                    })
                    .then(() => {

                        this.checkBlockedUser(auth.currentUser);

                    })
                    .then(() => {

                        if (this.getParams.formtype != 'passwordreset'
                            && this.getParams.formtype != 'changeemail'
                            && this.getParams.formtype != 'signup')
                        {

                            return this.processLogin(auth.currentUser, null);

                        }

                    })
                    .catch((err101) => {

                        this.errorMsg = err101.code;
                        this.performingRequest = false;

                    })
                    .then(() => {

                        this.performingRequest = false;

                    });

                return promise;

            },



            checkBlockedUser(user) {
                const hash = md5(user.email.toLowerCase());

                let blocked = false;

                // zeljko@intechopen.com
                /*
                if (hash == '134dd4ce369950eb62dd22928e425ca7') {
                    blocked = true;
                }
                */

                // zeljko+test1@intechopen.com
                /*
                if (hash == '56561a4d9d2f9334bbbfb3fcc639ec64') {
                    blocked = true;
                }
                */
                // ivo@intechopen.com
                /*
                if (hash == 'f63a5afb7013b754143b168a1adcdabc') {
                    blocked = true;
                }
                */
                // francisco.bulnes@tesch.edu.mx
                if (hash == 'cdbeca312017bdeb68693858603be968') {
                    blocked = true;
                }

                if (blocked) {
                    var error = new Error('unknown error 1');
                    error.code = 'auth/unknown-error-1';

                    throw error;
                }
            },

            openModal() {
                this.showModal = true;
                document.body.classList.add('modal-open');
            },

            onSearchCountry(search, loading) {
                if (search.length >= 1) {
                    loading(true);

                    debounce(() => {
                        // const pattern = new RegExp('\\b' + search, 'i');
                        const patterns = search.split(/[ ,]+/)
                            .filter((str) => str.length > 0)
                            .map((str) => new RegExp('\\b' + str, 'i'));

                        this.countryOptions = this.countries.filter((item) => {
                            // return item.name.toLowerCase().indexOf(search.toLowerCase()) > -1
                            // return pattern.test(item.name);

                            for (var i = 0; i < patterns.length; i++) {
                                if (!patterns[i].test(item.name))
                                    return false;
                            }

                            return true;
                        });

                        loading(false);
                    })();
                }
            },

            /*
            onSearchInstitution(search, loading) {

                const searchRemote = (search) => {
                    return this.lookupInstitution(search)
                        .then((result) => {
                            var data = result.data ? result.data : [];

                            data.push({
                                grid_id: gridIdOther,
                                name: '... Unlisted institution ...'
                            });

                            data.push({
                                grid_id: gridIdNoInstitution,
                                name: '... No institution ...'
                            });

                            this.institutionOptions = data;

                            if (search != this.institutionSearchTerm) {
                                return searchRemote(this.institutionSearchTerm);
                            }
                        });

                    
                }

                if (search.length >= 3) {
                    debounce(() => {
                        this.institutionSearchTerm = search;

                        if (!this.institutionSearchWIP) {
                            this.institutionSearchWIP = true;

                            loading(true);

                            searchRemote(search)
                                .then(() => {
                                    loading(false);
                                    this.institutionSearchWIP = false;
                                });
                        }

                    })();
                }
            },
            */

            mandatoryProposalTitleCheck() {
                if (this.sourceParam.search('(^R-)|(-CP$)') >= 0) {
                    return true;
                } else {
                    return false;
                }
            },

            loginFirebase() {

                this.performingRequest = true;
                this.errorMsg = '';

                var currentUser = null;

                getAuthPromise()
                    .then((auth) => {
                        currentUser = auth && auth.currentUser ? auth.currentUser : null;

                        return signInWithEmailAndPassword(auth, this.loginForm.email, this.loginForm.password)
                    })
                    .then((user) => {

                        // user.user.email
                        this.checkBlockedUser(user.user);

                        return user;
                    })
                    .then((user) => {
                        // console.log("user:", user);

                        return this.processLogin(/* currentUser */ user.user, null);

                    })
                    .catch(err102 => {

                        this.errorMsg = err102.code;
                        this.performingRequest = false;

                        const data = {
                            'note': 'sso login error',
                            'dataset': {
                                'code': err102.code || null,
                                'name': err102.name || null,
                                'message': err102.message || null,
                                'string': String(err102),
                                'email': this.loginForm.email,
                            }
                        };

                        return this.logData(data);
                    })
                    .then(() => {
                            
                        this.performingRequest = false;

                    });

            },

            signupFirebase() {

                this.performingRequest = true;
                this.errorMsg = '';

                if(!this.checkboxState && !this.isFromWelcomePage) {
                    this.errorMsg = 'signup/missing-privacy-check';
                    this.formIsValid = false;
                    this.performingRequest = false;

                } else if(!this.signupForm.name
                    || (this.mandatoryProposalTitleCheck() && 
                        (!this.signupForm.chapterTitle || this.signupForm.chapterTitle=='')
                    ) || !this.signupForm.lastname
                    || !this.signupForm.email
                    // || !this.signupForm.email2
                    // || this.signupForm.email2 == ''
                    || !this.signupForm.password
                    || !this.signupForm.title
                    || this.signupForm.title == ''
                    || !this.signupForm.country
                    || this.signupForm.country == ''
                    // || !this.signupForm.institution || this.signupForm.institution == ''
                    // || (this.signupForm.institution == gridIdOther
                    //        && (!this.signupForm.institutionOther || this.signupForm.institutionOther == ''))
                    || (this.getParams.formV !== undefined
                        && this.getParams.formV !== null
                        && (this.getParams.formV.search('O')>=0
                            && (!this.signupForm.orcid==''
                                && !this.signupForm.orcid.match(/^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{3}[0-9X]{1}$/)
                            )
                        )
                    )
                ) {

                    this.formIsValid = false;
                    this.errorMsg = 'signup/missing-fields';
                    this.performingRequest = false;

                } else if (!this.signupForm.email.match(validEmailRegexp)) {

                    this.formIsValid = false;
                    this.errorMsg = 'auth/invalid-email';
                    this.performingRequest = false;

                /*
                 * } else if (this.signupForm.email != this.signupForm.email2) {
                 *
                 *    this.formIsValid = false;
                 *    this.errorMsg = 'signup/repeat-email-must-match';
                 *    this.performingRequest = false;
                 */
                } else {

                    this.formIsValid = true;

                }

                if (!this.formIsValid) {
                    var dataset = { ...this.signupForm };

                    dataset.password = ''; // dont log password!

                    // logging failed validation
                    return this.logData({
                        note: "signupFirebase(), validation failed",
                        errorMsg: this.errorMsg,
                        dataset: dataset,
                    });

                }


                this.errorMsg = '';

                var currentUser = null;

                getAuthPromise()
                    .then((auth) => {

                        currentUser = auth && auth.currentUser ? auth.currentUser : null;

                        return createUserWithEmailAndPassword(auth, this.signupForm.email, this.signupForm.password)

                    })
                    .then((user) => {
                        currentUser = user.user;

                        // user.user.email
                        this.checkBlockedUser(currentUser);
                    })
                    .then(() => {
                        var dataset = { ...this.signupForm, uid: currentUser.uid };

                        dataset.password = ''; // dont log password!

                        return this.logData({
                            'note': "signupFirebase() debug user data",
                            'dataset': dataset,
                        });
                    })
                    .then(() => {

                        console.log("XXXX this.signupForm:", this.signupForm);

                        return this.processLogin(currentUser, this.signupForm);

                    }).catch(err103 => {
                        const data = {
                            'note': 'sso signup error',
                            'dataset': {
                                'code': err103.code || null,
                                'name': err103.name || null,
                                'message': err103.message || null,
                                'string': String(err103),
                                'email': this.signupForm.email,
                            }
                        };

                        this.errorMsg = err103.code;
                        this.performingRequest = false;

                        return this.logData(data);

                    })
                    .then(() => {

                        this.performingRequest = false;

                        if (this.errorMsg=='auth/email-already-in-use') {
                           this.switchToLogin(this.errorMsg);
                        }
                        
                    });

            },

            loginMng(currentUser, token) {
                return this.logData({
                        note: "loginMng(), success",
                        dataset: {
                            // token: token,
                            returl: this.getParams.returl,
                            email: currentUser ? currentUser.email : null,
                            uid: currentUser ? currentUser.uid : null,
                        },
                    })
                    .then(() => {
                        // let location1 = "https://mts.intechopen.com/";
                        let location1 = 'https://mts.intechopen.com/index/loginwithfirebase';

                        if (this.getParams.returl) {
                            location1 = this.getParams.returl;
                        }

                        location1 += '?token='+token;

                        // if (process.env.NODE_ENV == 'development') {
                        //     alert("redirect: " + location1);
                        // }

                        window.location = location1;
                    });
            },

            loginWww(currentUser, token) {
                return this.logData({
                        note: "loginWww(), success",
                        dataset: {
                            // token: token,
                            email: currentUser ? currentUser.email : null,
                            uid: currentUser ? currentUser.uid : null,
                            returl: this.getParams.returl,
                        },
                    })
                    .then(() => {
                        let location2 = process.env.VUE_APP_FRONTEND_DOMAIN;

                        if (this.getParams.returl) {
                            location2 = this.getParams.returl;
                        }

                        // if (process.env.NODE_ENV == 'development') {
                        //     alert("redirect: " + location2);
                        // }

                        window.location = location2;
                    });
            },

            getSfEnv() {
                let sfEnv = '';

                switch (this.getParams.env) {
                    case 'dev':
                    case 'https://dev-intechopen.cs174.force.com':
                        sfEnv = 'dev';
                        break;
                    case 'uat':
                    case 'https://intechopen--uat.sandbox.my.site.com':
                        sfEnv = 'uat';
                        break;
                    case 'prod':
                    case 'https://publish.intechopen.com':
                        sfEnv = 'prod';
                        break;

                    default:
                        if (process.env.VUE_APP_ACTUAL_ENV == 'stg') {
                            sfEnv = 'uat';
                        } else if (process.env.VUE_APP_ACTUAL_ENV == 'dev') {
                            sfEnv = 'uat';
                        } else {
                            sfEnv = 'prod';
                        }
                        break;
                }

                return sfEnv;
            },

            getCreateUserSf (user, signupData, requestSid) {
                let formData = null;

                if (signupData != null) {

                    formData = {
                        'uid': user.uid,
                        'email': user.email,
                        'lastName': signupData.lastname,
                        'firstName': signupData.name,
                        'country': signupData.country,
                        'academicTitle': signupData.title,
                        'chapterTitle':  signupData.chapterTitle,
                        'orcId': signupData.orcid,
                        // 'gridId': signupData.institution,
                        // 'gridIdOther': signupData.institutionOther,
                    };

                }

                const getCreateUserPost = {
                    'sfEnv': this.getSfEnv(),

                    'ssoToken': this.ssoToken,

                    'requestSid': requestSid ? 1 : 0,
                    'redirectUrl': requestSid && this.getParams.redirecturl ? this.getParams.redirecturl : null,

                    'postData': {
                        'email': user.email,
                        'uid': user.uid,
                        'formdata': formData,
                        'bookWebsiteId': this.getParams.bookid || null,
                        'subseriesWebsiteId': this.getParams.subseriesid || null,
                        'editorContact': this.getParams.editorcontact || null,
                        'callEmail': this.getParams.callemail || null,
                        'sourceParam': Cookie.get('sourceParam'),
                        'mpRequired': this.getParams.mpRequired || null,
                    }

                };

                console.log("XXXXXXXXX getCreateUserPost = ", getCreateUserPost);

                return axios.post(sfCreateUserAPI, getCreateUserPost)
                    .catch((error) => {
                        var error2 = new Error('getCreateUserSf proxy error');
                        error2.name = 'getCreateUserSf proxy error';
                        error2.code = 'auth/internal-error-proxy-sf-api';
                        error2.message = error.message || '';

                        throw error2;
                    })
                    .then(response => {

                        if (!response.data || !response.data.status) {
                            var error = new Error('getCreateUserSf failed');
                            error.name = 'getCreateUserSf failed';
                            error.code = 'auth/internal-error-sf-api';
                            error.message = response.data ? (response.data.message || null) : null;

                            throw error;
                        }

                        /*
                        return {
                         [status] => true|false
                         [username] => ....
                         [chapterId] => ....
                         [message] => 
                        }
                        */
                        return response.data;

                    })
                    .catch(err104 => {
                        this.errorMsg = err104.code;

                        return this.logData({
                                note: "getCreateUserSf(), case sf failed",
                                response: String(err104),
                                email: user ? user.email : null,
                                uid: user ? user.uid : null,
                            })
                            .then(() => {
                                return null;
                            });
                    });

            },

            resetPassword() {
                this.performingRequest = true;
                this.errorMsg = '';

                // fb.auth.sendPasswordResetEmail(
                getAuthPromise()
                    .then((auth) => {
                        return sendPasswordResetEmail(auth,
                            this.passwordForm.email, {
                            url: `https://auth.intechopen.com${this.$route.fullPath}`,
                        })
                    })
                    .then(() => {
                        // Logging
                        const data = {
                            'note': 'sso password reset requested',
                            'dataset': {
                                'email': this.passwordForm.email,
                                'redirectUrl': this.getParams.redirecturl,
                            }
                        };

                        return this.logData(data);
                    })
                    .then(() => {
                        this.passwordResetSuccess = true;
                        this.passwordForm.email = '';
                        this.performingRequest = false;
                    })
                    .catch(err105 => {

                        this.errorMsg = err105.code;
                        this.performingRequest = false;

                        const data = {
                            'note': 'sso password reset error',
                            'dataset': {
                                'error': err105,
                                'email': this.passwordForm.email,
                                'redirectUrl': this.getParams.redirecturl,
                            }
                        };

                        return this.logData(data);
                    })
            },

            /*
            logout(withoutRedirect){

                getAuthPromise()
                    .then((auth) => {
                        Cookie.erase("sso-token");

                        return auth.signOut()
                    })
                    .then(() => {

                    if (!withoutRedirect) {

                        var location = 'https://www.intechopen.com'

                        // if (process.env.NODE_ENV == 'development') {
                        //     alert("redirect: " + location);
                        // }

                        window.location = location;

                    } else {

                        this.errorMsg = 'Please login to complete this actions.';
                        this.params.formtype = 'login';
                        this.params.auth = true;
                        this.$store.commit('setParams', this.params);

                    }

                })

            },
            */

            generateTokenMngV4(user) {
                /*
                mng:
                user: { email: ...., uid: ... }
                */

                // if (this.getParams.referer == 'mng') {
                let payload = {
                    'uID': user.uid,
                    'email': user.email,

                    'ssoToken': this.ssoToken,
                    'formtype': 'login',

                    'bookId': this.getParams.bookid,
                    'redirectUrl': this.getParams.redirecturl,
                    'callEmail': this.getParams.callemail,
                    'editorContact': this.getParams.editorcontact,
                    'utmSource': this.getParams.utmSource,
                    'utmMedium': this.getParams.utmMedium,
                    'utmCampaign': this.getParams.utmCampaign,
                    'utmTerm': this.getParams.utmTerm,
                    'utmContent': this.getParams.utmContent
                };

                if (this.getParams.formtype == 'signup') {
                    if (this.getParams.formV!==undefined && this.getParams.formV!==null){
                        var formParams=this.getParams.formV;
                    } else {
                        var formParams="";
                    }

                    Object.assign(payload, {
                        'formtype': 'signup',

                        'formParams': formParams,

                        'firstName': this.signupForm.name,
                        'lastName': this.signupForm.lastname,
                        'title': this.signupForm.title,
                        'country': this.signupForm.country,
                        'academicTitle': this.signupForm.title,
                        'orcid': this.signupForm.orcid,
                        // 'gridId': this.signupForm.institution,
                        // 'gridIdOther': this.signupForm.institutionOther,

                        'chapterTitle': this.signupForm.chapterTitle,
                    });
                }

                console.log("generateTokenMngV4 payload:", payload);

                return axios.post(mtsTokenAuthAPI, payload)
                    .catch((error) => {
                        var error2 = new Error('mtsTokenAuthAPI proxy error');
                        error2.name = 'mtsTokenAuthAPI proxy error';
                        error2.code = 'auth/internal-error-proxy-sf-api';
                        error2.message = error.message || '';

                        throw error2;
                    })
                    .then(response => {

                        if (!response.data || !response.data.status) {
                            var error = new Error('mtsTokenAuthAPI failed');
                            error.name = 'mtsTokenAuthAPI failed';
                            error.code = 'auth/internal-error-mts-token-auth-api';
                            error.message = response.data ? (response.data.message || null) : null;

                            throw error;
                        }

                        return response.data.token;
                    })
                    .catch(err106 => {
                        this.errorMsg = err106.code;

                        return this.logData({
                                note: "mtsTokenAuthAPI(), case sf failed",
                                response: String(err106),
                                email: user ? user.email : null,
                                uid: user ? user.uid : null,
                            })
                            .then(() => {
                                return null;
                            });
                    });

            },

            processLoginSession(currentUser, signupData) {

                // signupData != null => registration
                
                if (currentUser) {

                    const uid = currentUser.uid;

                    if (window && window.gtag) {
                        const eventName = signupData ? 'registerUser' : 'loginUser';

                        window.gtag('event', eventName, {
                            user_id: uid,
                        });
                    }

                    this.ssoToken = generateSessionToken(currentUser.uid, currentUser.email);

                    console.log("sso-token SET !!!! tld:", Cookie.getTLDdomain());

                    Cookie.set("sso-token", this.ssoToken, /* days */ 30, Cookie.getTLDdomain());
                } else {

                    this.ssoToken = null;
                    Cookie.erase("sso-token");

                }

            },

            processLoginSf(currentUser, signupData) {

                return this.getCreateUserSf(currentUser, signupData, /* requestSid: */ true)
                    .then((data) => {
                        if (!data) return;

                        // data.username => sf community user
                        // data.chapterId => sf chapterId

                        let location = data.base_url + '/secur/frontdoor.jsp'
                            + '?sid=' + data.access_token
                            + '&retURL=' + encodeURIComponent(data.redirectUrl);

                        // if (process.env.NODE_ENV == 'development') {
                        //     alert("redirect " + location);
                        // }

                        this.errorMsg = '';
                        this.performingRequest = false;

                        return this.logData({
                                note: "loginSf(), success",
                                email: currentUser ? currentUser.email : null,
                                uid: currentUser ? currentUser.uid : null,
                            })
                            .then(() => {
                                if (process.env.NODE_ENV == 'development') {
                                    alert("xxx location: " + location);
                                    window.location = location;
                                } else {
                                    window.location = location;
                                }
                            });
                        
                    });

            },

            processLoginMng(currentUser, signupData) {
                
                Promise.resolve()
                    .then(() => {
                        return this.generateTokenMngV4(currentUser);
                    })
                    .then((token) => {
                        return this.loginMng(currentUser, token);
                    });

            },

            processLoginWww(currentUser, signupData) {

                return this.getCreateUserSf(currentUser, signupData, /* requestSid: */ false)
                    .then((data) => {
                        if (!data) return;
                        // ??? TODO
                    })
                    .then(() => {
                        return this.loginWww(currentUser, null);
                    });

            },

            processLogin(currentUser, signupData) {

                return Promise.resolve()
                    .then(() => {
                        return this.processLoginSession(currentUser, signupData);
                    })
                    .then(() => {
                        console.log("call processLoginSf");

                        if (this.getParams.referer == 'sf') {
                            return this.processLoginSf(currentUser, signupData);
                        } else if (this.getParams.referer == 'www') {
                            return this.processLoginWww(currentUser, signupData);
                        } else if (this.getParams.referer == 'mng') {
                            return this.processLoginMng(currentUser, signupData);
                        } else {
                            // default sf.
                            return this.processLoginSf(currentUser, signupData);
                        }
                    });
            },

            errorClose() {

                this.errorMsg = ''

            },

            switchToLogin (err107) {
                
                this.errorMsg = ''
                if(err107=='auth/email-already-in-use'){
                    this.errorMsg='auth/email-already-in-use';
                    this.loginForm.email=this.signupForm.email;
                }
                this.params.formtype = 'login'
                this.$store.commit('setParams', this.params)

            },

            switchToSignup () {

                this.errorMsg = ''
                this.params.formtype = 'signup'
                this.$store.commit('setParams', this.params)

            },

            switchToPasswordReset () {

                this.errorMsg = ''
                this.loginForm.password = ''
                this.params.formtype = 'passwordreset'
                this.$store.commit('setParams', this.params)

            },

            /*
            lookupInstitution(search) {
                
                return axios.get("https://mts.intechopen.com/sso/institution-lookup", {
                        params: {q: search},
                    })
                    .then((response) => {
                        return response;
                    }).catch((err) => {
                        this.errorMsg = err.code
                    });

            },
            */

        }

    }

</script>

<style lang="scss">
// FIXME: I continue my shame CSS
// FIXME: Style Dropdown -> Add correct Ivory instead white color
.v-select,
.vs--single,
.vs--searchable {
  font-family: Relative Faux, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.7em;
  line-height: 1.5;
  display: block;
  padding: 0.8rem 1.5rem;
  border: 1px solid #e10000;
  border-radius: 2px;
  background: transparent;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 25rem;
}
.vs__dropdown-toggle {
  border: none !important;
}
.vs__search::placeholder {
        color: #666666;
        opacity: 0.8;
        font-family: "Relative Faux", sans-serif;
}
#hover_label {
  color: red;
  font-weight: bolder;
  cursor: pointer;
  padding-left: 1rem;
  font-size: 1.2rem;
  display: inline;
}
#title_hover {
  display: none;
  position: relative;
  visibility: hidden;
  background-color: #fffad4;
  font-size: 1.2em;
  overflow: auto;
}
#hover_label:hover + #title_hover {
  z-index: 3;
  bottom: 5vh;
  position: absolute;
  width: 20vw;
  overflow: unset;
  padding: 5px;
  left: -10vw;
  top: 2vh;
  box-shadow: 1px 1px 5px 2px #fdf9d8;
  visibility: visible;
  display: block;
}
.error-msg {
  margin: 2rem auto;
  width: 50%;
  text-align: center;
}

.validation{
    margin-top: 15px;
    color: red;
    font-size: 11px;
}

.collaborate-with-our-community {
    box-sizing: border-box;
    background: rgba(0, 0, 0, .1);
    padding: 2rem;
    margin-top: 0rem;
    position: relative;
    bottom: -6rem;
}

    .title {
        font-family: 'FSBrabo', serif; font-weight: 400; font-style: normal; 
        font-size: 25px;
        text-align: left;
        margin-bottom: 0px;
        color: #000;
    }

    .registration-form{
        margin-top: 0;
        margin-bottom: 25px;

        &__data{
            text-align: left;
            margin-top: 50px;
            font-size: 14px;

            .reg-input{
                margin-right: 0px;
                display: block;
                margin-bottom: 10px;
            }

            .red-underlined{
                color: rgb(225, 0, 0);
                font-weight: 600;
                border-bottom: 1px solid rgb(225, 0, 0);
                text-decoration: none;
            }

            .validation{
                margin-top: 15px;
                color: red;
                font-size: 11px;
            }

            .register{
                margin-bottom: 10px;
                margin-top: 20px;

                a{
                    font-family: 'Relative Faux', sans-serif; font-weight: 400; font-style: normal;
                    width: 100%;
                    line-height: 1;
                    font-size: 14px;
                    letter-spacing: 1px;
                    color: #fffad2;
                    text-transform: uppercase;
                    text-decoration: none;
                    text-align: center;
                    display: inline-block;
                    padding: 1rem 1rem;
                    border: 1px solid #e10000;
                    border-radius: 4px;
                    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
                    background: #e10000;
                    box-sizing: border-box;
                }

                a:hover{
                    background: #b40000;;
                    border: 1px solid #b40000;;
                }
            }
        }
    }

    @media only screen and (min-width: 1000px) {
        .collaborate-with-our-community {
            background: rgba(0, 0, 0, .1);
            width: 100vw;
            left: calc((100vw - 550px) / -2);
        }

        .title { 
            //@include fontSerifNormal;
            font-family: 'FSBrabo', serif; font-weight: 400; font-style: normal; 
            text-align: center;
            font-size: 26px;
            font-weight: 500;
        }

        .registration-form{
            margin-top: 0;
            margin-bottom: 25px;

            &__data{
                text-align: center;
                margin-top: 50px;
                font-size: 16px;

                .reg-input{
                    margin-right: 10px;
                    display: inline-block;
                    margin-bottom: 0px;
                }

                .red-underlined{
                    color: rgb(225, 0, 0);
                    font-weight: 600;
                    border-bottom: 1px solid rgb(225, 0, 0);
                }

                .validation{
                    margin-top: 15px;
                    color: red;
                    font-size: 11px;
                }

                .register{
                    margin-bottom: 10px;
                    margin-top: 20px;

                    a{
                        width: 300px;
                        font-weight: 400;
                        //font-size: 16px;
                        color: #fffad2;
                        padding: 1.2rem 1.8rem;
                        border: 1px solid #e10000;
                        border-radius: 2px;
                        background: #e10000;
                    }

                    a:hover{
                        background: #b40000;;
                        border: 1px solid #b40000;;
                    }
                }
            }
        }
    }
</style>
