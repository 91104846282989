<template>

    <div>
        <page-header></page-header>
        <div class="wrap">
            <div v-if="validMode" class="content">
                <component v-bind:is="mode"></component>
            </div>
            <div v-else class="content">
                <div class="auth-form">
                    <h2 class="sub-title">Error encountered</h2>
                    <p>The selected page mode is invalid.</p>
                </div>
            </div>
        </div>
        <page-footer></page-footer>
    </div>

</template>

<script>
import PageHeader from '@/components/main/PageHeader';
import PageFooter from '@/components/main/PageFooter';

export default {
    name: 'ActionPage',

    components: {
        PageHeader,
        PageFooter,
        ResetPassword: () => import('@/components/actions/ResetPassword.vue'),
    },

    computed: {
        mode() {
            return this.$route.query.mode || '';
        },

        validMode() {
            return ['resetPassword'].indexOf(this.mode) > -1;
        },
    },
};
</script>

<style lang="scss"></style>
