<template>

    <div id="app">

        <NotFoundPage v-if="errorStatus === 404" />
        <ErrorPage v-else-if="errorStatus === 500" />
        <MaintenancePage v-else-if="errorStatus === 503" />
    	<router-view v-else/>
        
    </div>

</template>

<script>

    import {mapGetters} from 'vuex'

    import { firebaseInit, maintanceModeEnabled, setMaintanceStatus } from './firebaseConfig';

    import NotFoundPage from '@/pages/NotFoundPage'
    import ErrorPage from '@/pages/ErrorPage'
    import MaintenancePage from '@/pages/MaintenancePage'

    const axios = require('axios');

    export default {

        components: {

            NotFoundPage,
            ErrorPage,
            MaintenancePage,

        },

        computed: mapGetters({

            errorStatus: 'errorStatus',

            maintenanceStatus: 'maintenanceStatus',
            
        }),


        mounted() {
            var promise = firebaseInit();

            if (maintanceModeEnabled()) {
                promise = promise .then(() => {
                        return axios.post('https://mts.intechopen.com/sso-maintence.php')
                    })
                    .then((response) => {
                        this.$store.commit('setMaintenanceStatus', response.data);
                    })
                    .catch((err) => {
                        this.$store.commit('setMaintenanceStatus', { status: 0, });
                    })
                    .then(() => {
                        return setMaintanceStatus(this.maintenanceStatus);
                    });
            }

            return promise;
        }

    }

</script>

<style lang="scss">

    @import './assets/scss/app.scss';

    body.modal-open {
        overflow-y: hidden;
    }
</style>
