<template>

    <div>

        <PageHeader />
        <Error />
        <PageFooter />

    </div>

</template>

<script>
// FIXME: Remove PageFooter
    import PageHeader from '@/components/main/PageHeader';
    import Error from '@/components/main/Error';
    import PageFooter from '@/components/main/PageFooter';

    export default {

        components: {

            PageHeader,
            Error,
            PageFooter
            
        }

    };

</script>

<style scoped>
</style>
