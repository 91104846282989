export default [
    '-----BEGIN RSA PRIVATE KEY-----'+
    'MIIEogIBAAKCAQEArgeYRmF+9oenOmFmm7LujHvCGHvvAzc/XrFlw6yNBKCAFINm'+
    '8WPAt+/UQPywmXWnsLb2YjHllAFSTolqu+TLsi9aNqkDbNBH8Nn5CrLqMBXTPh2/'+
    'mOi1cwYctI/CC8ZXcMmE+NojjyFxQ3qVBx9fx4qMMyJIIs6yay3bzRYKVkcSLBCC'+
    'CLwySLxTK7f8JvrYi9PNtnQDyZOk5jhqn1ZIEyuNpHxM0fXAUDThXff6mKU6Yl7K'+
    'QuplbgjA6QMZowwSFxN+R9MR+eADMKzPJm5f1ytf1yZuvxJF01w5nKwa6K1enwzP'+
    'ABlYcbYF/x8lxlG5HXTihtoq7hgtRi+InMqdaQIDAQABAoIBABZAqlv1g4KKffud'+
    'EL2/93jHKl5vGnTSz6uQC28l1TJOQ5Jb6wHau33JkN5ucTZuMO8Lth2AO9bdBGU+'+
    'dQNpkhJKs8Jhgiqbx1coyAtyLTfQ0p4St7kZqHn4whrkdu+CgRPO8M3RNtLKd+0k'+
    '84FhpaGiOaMgMxEgVPgXeQ+UTeYSImY8WF0lAboHctQGEdyqvowU0Qc4U0U7MT29'+
    'rsZq48hI0PUCPgAQBhli/z/csS3JbS2uhIMykQ1bdovChUC7Hb998uEe/ylki0tv'+
    'KQWGqLJeSlUliDOrw27tDyS3kbIOXhLowUkZ/ci5gLBtdJnQ2uUMrel84bx0o68Z'+
    'zYn/8sECgYEA2BoF/xC3KISEn4sRuBrWCbdUc8POMz6PuNuZhV2FOkryDutV8Pij'+
    'Ilq0nTw5I4+Zo8Uf3Dp4XDakXQAk/rRGIu3xlvX6fpEBngh2UVqXiGw1YHUjVLlR'+
    'hJ17ZT5TdlOphqEiIwA37gtDQtWNx1xooajwT/DPVrdALGZl0SxWsnUCgYEAzikN'+
    'XWjDsquezwP3ly3Z7QxcP07dNyPOkbXZiKEzWqsfrH2qPPw1d+m8nIOgQl6f9y0U'+
    'VrPKvhF+6OHer2xhDAqXwABIviqO0yJFwH5/xS3k3mg/jB3Tmai6Jwpv6JhvP/D/'+
    '7Y/ZYn8nUwzJTZKt7VyUnKmCHZztQt4na85gOKUCgYAV/YfwrbXFtL5+Mbuq3Wg8'+
    'cuBwK1O3WT1s1UPpE1w0U14QcJg9Pj/zhgFtIqy51qoOxUd5tKV81L6jDBm3pYah'+
    'lU8JSpyj9oOQnlHT84EKC+DeoOzoeTc3ZIMjCVWa6+aLYahEoYgPfCvrCCo2SEwJ'+
    'b1SM/fdLXrh0DDN+NqHX5QKBgBDvvnFWEf2zq7BGGykIU0buJQXubK4C+w1taxmW'+
    'ZKmmNUvuZvXroljfkucoyRmqvGmKAom864MB3PM02x5+QNrEZAMaAt/cjy85OZG+'+
    '0rBYPN0oo0/Xg3K0MdbTPK5XF4yhN+VEVA+LcqeX/VGqIy/E01CrfE0Cf1tm9MYe'+
    'hjkhAoGAehzWRxACw/1obvLObm9gVBFEU1pQHqJLLNijvwa35NLwZQp2zyVkBFjU'+
    'kIWWLIF9iOXPuVONGTjNJc1wx7lIrIrXatDNyLtyxnGLPNtANyUmhW8+4U7Ql1iv'+
    '33XUsfPwoIhgA0D5ClT0EXD96Q3mS8zMsBOYm935xPMfYJVavHI='+
    '-----END RSA PRIVATE KEY-----'
];
