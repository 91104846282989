<template>
    <div v-if="value" id="modal" class="modal" @click="close">
        <div class="modal__content">
            <div class="modal__content__1">
                <span class="close-btn" @click="close">×</span>
                <h1 class="modal-title">Open Access Publishing fee (OAPF)</h1>
                <p class="modal-price">1400 GBP/chapter</p>
                <p class="modal-info"><strong class="black">No hidden costs.</strong> Flat rate OA Publishing fee for manuscripts up to 20 pages. Authors or their institutions/funding bodies will need to pay the OAPF <strong class="black">only if the full chapter is accepted for publication.</strong></p>
            </div>
            <div class="modal__content__2">
                <p class="title"><strong>The article publishing fee covers:</strong></p>


                <div class="modal-list">
                    <div class="list">
                        <p>English language copyediting &amp; proofreading - correction of grammatical, spelling &amp; other common errors</p>
                        <p>Indexing and listing across major repositories</p>
                        <p>Visibility on the strongest OA platform</p>
                    </div>
                    <div class="list">
                        <p>Permanent and unrestricted online access to your work &amp; long-term archiving</p>
                        <p>XML Typesetting and pagination - web (PDF, HTML) and preparation of print files</p>
                        <p>Online manuscript tracking system to facilitate your work (Author Panel)</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        }
    },

    methods: {
        close(e) {
            const classList = e.currentTarget.classList;
            if (e.target.classList.contains('modal__content') || classList.contains('close-btn')) {
                document.body.classList.remove('modal-open');
                this.$emit('input', !this.value);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .modal{
        display: block;
        position: fixed;
        z-index: 1000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.7);

        &__content{
            padding: 0px 0px;
            display: block;
            text-align: center;
            margin: auto;

            &__1{
                background: #FFFAD2;
                width: auto;
                text-align: center;
                margin: auto;
                display: block;
                padding: 0rem 2rem 2rem 2rem;

                .close-btn{
                    margin-left: 95%;
                    color: black;
                    font-size: 48px;
                    font-weight: bold;
                    transition: 0.3s;
                    line-height: 1;
                }

                .close-btn:hover{
                    color: #bbb;
                    text-decoration: none;
                    cursor: pointer;
                }

                .modal-title{
                    margin-bottom: 5px;
                    font-family: 'FSBrabo', serif;
                    font-size: 25px;
                    font-weight: 500;
                }

                .modal-price{
                    margin-bottom: 20px;
                    font-size: 22px;
                    color: #e10000;
                    font-family: 'FSBrabo', serif;
                    font-weight: 400;
                    line-height: 1;
                }

                .modal-info{
                    color: black;
                    font-family: 'FSBrabo', serif;
                    font-size: 17px;
                    font-weight: 400;
                    line-height: 1;

                    .black{
                        color: black;
                    }
                }
            }

            &__2{
                background-color: #e10000;
                width: auto;
                margin: auto;
                text-align: center;
                display: block;
                padding: 25px 25px;
                
                .title{
                    color: #fffad2;
                    margin-bottom: 20px;
                    text-transform: uppercase;
                    font-size: 17px;
                    font-weight: 400;
                }
                .modal-list{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    color: #fffad2;

                    .list{
                        width: 100%;
                        text-align: center;
                        line-height: 1.6;
                        font-size: 17px;
                        padding-right: 0px;

                        p{
                            font-family: 'FSBrabo', serif;
                            font-size: 17px;
                            font-weight: 400;
                            padding: 0px 25px 25px 25px;
                            line-height: 1;
                        }
                    }

                    .list:last-of-type{
                        padding-right: 0px;
                        padding-left: 0px;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1000px) {
        .modal{

            &__content{
                padding: 90px 0px;

                &__1{
                    width: 500px;
                    padding: 20px 100px;

                    .close-btn{
                        margin-left: 550px;
                    }
                }

                &__2{
                    width: 600px;
                    padding: 20px 50px 20px 50px;

                    .modal-list{
                        flex-direction: row;

                        .list{
                            width: 50%;
                            padding-right: 25px;
                        }

                        .list:last-of-type{
                            padding-right: 0px;
                            padding-left: 25px;
                        }
                    }
                }
            }
        }
    }
</style>