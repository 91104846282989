import Vue from 'vue'
import App from './App'
import router from './router'
import { store } from './store.js'
// import init from './firebaseConfig';

const axios = require('axios')
const md5 = require('blueimp-md5');

Vue.config.productionTip = false

// init();

if (window) {
    window.clockDiff = 0;
}

function logData(data) {

    const generateCurrentDate = () => {
        var today = new Date();
        var yy = today.getFullYear();
        var mm = today.getMonth()+1;
        var dd = today.getDate();
        if(dd<10) dd='0'+dd;
        if(mm<10) mm='0'+mm;

        return yy+'-'+mm+'-'+dd;
    };

    if (!data) data = {};

    if (typeof data !== 'object') {
        data = {
            'note': 'unkwown',
            'data': data,
        };
    }

    if (!('dataset' in data)) {
        data['dataset'] = {};
    }

    if (process.env.NODE_ENV === 'development') {
        data['note'] = '[development] ' + data['note'];
    }

    if (process.env.NODE_ENV === 'development') {
        console.log("logData:", data);
    }

    data['secret'] = md5('secret 4 password ' + generateCurrentDate());

    return axios.post('/api/__logger', data)
        .catch((err) => {
            if (process.env.NODE_ENV === 'development') {
                console.log("error calling logger:", err);
            }
        })
        .then((result) => {
            // console.log("result:", result);

            var server_time = null;

            if (result && result.headers && ("x-date" in result.headers)) {
                try {
                    server_time = parseInt(result.headers['x-date']);
                } catch (err) { }
            }

            if (server_time) {
                var local_time = new Date();
                local_time = Math.floor(local_time.getTime());

                if (window) {
                    window.clockDiff = server_time - local_time;
                    // console.log("clockDiff:", window.clockDiff);
                }
            }

            /*
            if (process.env.NODE_ENV === 'development') {
                return;
            }

            // production logger.
            return axios.post('https://mts.intechopen.com/index/ssologger', data)
                .catch(function() {
                    console.log("error calling logger2:", err);
                });
            */
        });
}

var loggerMixin = {
    methods: {
        logData: logData,
    },
};

Vue.mixin(loggerMixin);

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
});


