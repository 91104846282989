

/*
- signInWithEmailAndPassword(auth, this.loginForm.email, this.loginForm.password)
- createUserWithEmailAndPassword(auth, this.signupForm.email, this.signupForm.password)
- sendPasswordResetEmail(auth,
    this.passwordForm.email,
    { url: `https://auth.intechopen.com${this.$route.fullPath}`, }
    )

- verifyPasswordResetCode(auth, this.oobCode)
- confirmPasswordReset(auth, this.oobCode, this.newPassword)

*/

function initializeApp(config) {
    return window.firebase.initializeApp(config);
}

function getAuth() {
    return window.firebase.auth();
}

export function signInWithEmailAndPassword(auth, email, password) {
    return auth.signInWithEmailAndPassword(email, password);
}

export function createUserWithEmailAndPassword(auth, email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
}

export function sendPasswordResetEmail(auth, email, opts) {
    return auth.sendPasswordResetEmail(email, opts);
}

export function verifyPasswordResetCode(auth, oobCode) {
    return auth.verifyPasswordResetCode(oobCode);
}

export function confirmPasswordReset(auth, oobCode, newPassword) {
    return auth.confirmPasswordReset(oobCode, newPassword);
}

export function signOutWrapper(auth) {
    return auth.signOut();
}

const config = {
    apiKey: "AIzaSyBogRTVigTioO52Nw1r36L2QXM47GBb7uU",
    authDomain: "intechopen-4d333.firebaseapp.com",
    databaseURL: "https://intechopen-4d333.firebaseio.com",
    projectId: "intechopen-4d333",
    storageBucket: "intechopen-4d333.appspot.com",
    messagingSenderId: "168124009987",
    appId: "1:168124009987:web:56fe77c4663e1365d90836",
    measurementId: "G-GG8G5VQLM4"
};

var initial_auth_state_loaded = false;

var maintance_status = null;

var status_finish_init = false;

var promise_waiting = [];

export function getAuthPromise() {
    return new Promise((resolve) => {
        if (!status_finish_init) {
            // console.log("put on wait...");
            promise_waiting.push(resolve);
        } else {
            resolve(getAuth());
        }
    });
};

// !!! CONFIG SWITCH to enable MAINTANCE PAGE functionality
var maintance_mode_enabled = false;

export function maintanceModeEnabled() {
    return maintance_mode_enabled;
};

export function setMaintanceStatus(maintance) {

    if (!maintance_mode_enabled) return;

    maintance_status = maintance;

    finishInit();
};

function finishInit() {
    var ok = false;

    if (!maintance_mode_enabled) {
        if (initial_auth_state_loaded) {
            ok = true;
        }
    } else {
        if (initial_auth_state_loaded && (maintance_status !== null && maintance_status === false)) {
            ok = true;
        }
    }

    if (ok) {

        status_finish_init = true;

        // console.log("finish_init = true");

        while (true) {
            var resolve = promise_waiting.shift();
            if (!resolve) break;

            resolve(getAuth());
        }

    }
}

export function firebaseInit() {
    return new Promise((resolve) => {
        initializeApp(config);

        const auth = getAuth();

        auth.onAuthStateChanged((user) => {

            // console.log("onAuthStateChanged: user=", user);

            initial_auth_state_loaded = true;

            finishInit();

        });

        resolve();
    });

};


