<template>

    <div>
        <PageHeader />
        <AuthForm />
        <PageFooter />
    </div>

</template>

<script>
// FIXME: Remove PageFooter
    import PageHeader from '@/components/main/PageHeader';
    import AuthForm from '@/components/AuthForm';
    import PageFooter from '@/components/main/PageFooter';

    export default {

        components: {

            PageHeader,
            AuthForm,
            PageFooter

        }

    };

</script>

<style scoped>
</style>
